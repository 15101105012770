//Pages
import EarlyAccess from './pages/EarlyAccess';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ManageCookies from './pages/ManageCookies';

const routes = [
	{
		path: '/',
		exact: true,
		component: EarlyAccess, //Home,
	},
	{
		path: '/privacy',
		exact: true,
		component: PrivacyPolicy,
	},
	{
		path: '/cookies',
		exact: true,
		component: ManageCookies,
	},
];
export default routes;
