import React from 'react';
import './GooglePlayButton.sass';

export const GooglePlayButton = () => {
	return (
		<a
			className='googleplaybutton'
			href='https://play.google.com/store/apps/details?id=app.kontas&utm_source=website&utm_medium=webpage&utm_campaign=website'
		>
			<img
				alt='Get it on Google Play'
				src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
			/>
		</a>
	);
};
