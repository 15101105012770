import React, { useState, useEffect } from 'react';
import './SmallFooter.sass';

function SmallFooter() {
	const [year, setDate] = useState();

	const getYear = () => setDate(new Date().getFullYear());
	useEffect(() => {
		getYear();
	}, []);
	return (
		<>
			<div className='small-footer'>
				<div className='footer-container'>
					<div className='left-content'>
						<p className='copyright'>
							Kontas © {year} Copyright. All rights reserved
						</p>
						<p className='disclaimer'>
							Google Play and the Google Play logo are registered trademarks of
							Google LLC.
						</p>
						<br/>
						<p className='disclaimer'>
							<a href='/privacy' >
								Privacy Policy
							</a>
							&nbsp; · &nbsp;  
							<a href='/cookies' >
								Cookies
							</a>
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default SmallFooter;
