import React from 'react';
//import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Styles
import './css/App.sass';
import './css/Animations.sass';

//Pages
import routes from './routes';

//Partials
import CookieConsentBar from './components/cookies/CookieConsentBar';

//Utils
import { ScrollTopButton } from './components/button/ScrollTopButton';

//Extras
//import Rellax from 'rellax';
//rellax.destroy();

//Prevent widows and orphans on p elements
import TypeMate from 'typemate';
import GoogleTagManager from './components/analytics/GoogleTagManager';
const typeMateInstance = new TypeMate();
typeMateInstance.apply();

function App() {
	return (
		<>
			<GoogleTagManager/>
			<Router>
				{/*<Navbar />*/}
				<CookieConsentBar />
				<Switch>
					{routes.map((route, index) => (
						<Route
							key={index}
							path={route.path}
							exact={route.exact}
							component={route.component}
						/>
					))}
				</Switch>
				{/*<Footer />*/ <ScrollTopButton />}
			</Router>
		</>
	);
}

export default App;
