import React from 'react';
import './ToggleSwitch.sass';

class ToggleSwitch extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			isChecked : this.props.defaultChecked === true
		};
		console.log("Default state: "+props.defaultChecked);
	}

 	onCheckChanged({target}) {
		if(this.props.onCheckedChanged !== undefined){
			this.props.onCheckedChanged(target.checked)
		};
		this.setState({
			isChecked: target.checked
		});
		console.log("State changed: "+target.checked);
	}

	render() {
		return (
			<>
				<div className='ToggleSwitch'>
					<label className='switch'>
						<input type='checkbox' checked={this.state.isChecked} onChange={this.onCheckChanged.bind(this)}/>
						<span className='slider'></span>
					</label>
				</div>
			</>
		)
	}
}

export default ToggleSwitch;
