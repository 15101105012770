import React from 'react';
import './Button.sass';

const STYLES = ['btn--primary','button-secondary', 'btn--two'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
	children,
	type,
	onClick,
	buttonStyle,
	buttonSize,
	className,
}) => {
	const checkButtonStyle = STYLES.includes(buttonStyle)
		? buttonStyle
		: STYLES[0];

	const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

	//se incluir a classe de estilo usar essa caso contrario usar a primeira classe do array de styles

	return (
		<button
			className={`btn ${checkButtonStyle} ${checkButtonSize} ${className}`}
			onClick={onClick}
			type={type}
		>
			{children}
		</button>
	);
};
