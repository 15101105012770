	
import React from 'react';

 function OverflowGradient({ direction, isVisible }) {
	const style = {
		pointerEvents: 'none',
		opacity: isVisible ? 1 : 0,
		transition: 'opacity 250ms ease-out',
	};
	switch (direction) {
		default:
			return (
				<div style={style} className='overflow-indicator down'>
					<span class='material-icons-round'>expand_more</span>
				</div>
			);
		case 'up':
			return (
				<div style={style} className='overflow-indicator up'>
					<span class='material-icons-round'>expand_less</span>
				</div>
			);
		case 'down':
			return (
				<div style={style} className='overflow-indicator down'>
					<span class='material-icons-round'>expand_more</span>
				</div>
			);
	}
}

export default OverflowGradient;