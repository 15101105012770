import React from 'react';
import cookieState from '../cookies/CookieState';

class GoogleTagManager extends React.Component {

    constructor(props){
        super(props)
        if(cookieState.areAnalyticsCookiesEnabled()){
            window["grantAnalyticsConsent"]();
        }
    }

    render() {
        return null;
    }
   
}

export default GoogleTagManager;