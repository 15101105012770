import Cookies from 'universal-cookie';

const cookies = new Cookies();
const analyticsCookiesKey = "analytics"
// 1 year
const maxCookieAge = 31536000;

class CookieState {

    areCookiesEmpty() {
        var cookie = cookies.get(analyticsCookiesKey,{ path: '/' });
        return cookie === undefined;
    }

    areAnalyticsCookiesEnabled() {
        var cookie = cookies.get(analyticsCookiesKey,{ path: '/' });
        return cookie === "true";
    }

    enableAllCookies() {
        this.enableAnalyticsCookies();
    }

    disableAnalyticsCookies(){
        cookies.set(analyticsCookiesKey, false,{ path: '/', maxAge: maxCookieAge});
    }

    enableAnalyticsCookies(){
        cookies.set(analyticsCookiesKey, true,{ path: '/', maxAge: maxCookieAge});
    }
}

const cookieState = new CookieState();

export default cookieState;
