import React, { useState } from 'react';
import './ScrollTopButton.sass';

export const ScrollTopButton = () => {
	const [showScroll, setShowScroll] = useState(false);

	var heightToScroll = 600;

	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset > heightToScroll) {
			setShowScroll(true);
		} else if (showScroll && window.pageYOffset <= heightToScroll) {
			setShowScroll(false);
		}
	};

	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	window.addEventListener('scroll', checkScrollTop);

	return (
		<div
			className={showScroll ? 'scrollTop show' : 'scrollTop'}
			onClick={scrollTop}
		>
			<span className='material-icons-round'>arrow_upward</span>
		</div>
	);
};
