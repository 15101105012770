import React from 'react';
import ToggleSwitch from '../toggle/ToggleSwitch';
import './Cookies.sass';

function Cookies(props) {
	return (
		<>
			<div className='main-section'>
				<br/>
				<p>
					Cookies are small files used to store information on web browsers.
					
					
				</p>
			</div>
			<div className='section'>
				<div className='title'>
					<h4>Analytics Cookies</h4>
					<ToggleSwitch onCheckedChanged={props.onAnalyticsCheckChanged} />
				</div>
				<p>
					We use these cookies to store identifiers used by our analytics partners.
					The information collected is anonymised.
				</p>
			</div>

			<div className='cookie-footer-label'>
				<p>
					You can change these settings anytime by visiting Cookies at the bottom of any page
				</p>
			</div>
		</>
	);
}

export default Cookies;
