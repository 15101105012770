import React from 'react';
import { Link } from 'react-router-dom';
import './css/Navbar.sass';

function Navbar(props) {
	return (
		<>
			<nav className={`navbar ${props.className}`}>
				<div className='navbar-container'>
					<Link to='/' className='navbar-logo'>
						<img
							alt='Kontas App Logo'
							className='logo'
							src='./images/kontas-logo_192.png'
						></img>
					</Link>
					<ul className='menu'>
						<li className='nav-link'>
							<a
								href='https://play.google.com/store/apps/details?id=app.kontas'
								target='_blank'
								rel='noreferrer'
							>
								Get App
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</>
	);
}

export default Navbar;
