import React from 'react';
import ReactModal from 'react-modal';
import Cookies from './Cookies';
import Overflow from 'react-overflow-indicator';
import { Button } from '../button/Button';
import CookieState from './CookieState';
import OverflowGradient from '../modal/OverflowGradient';
ReactModal.setAppElement('#root');

class CookieConsentModal extends React.Component {

	analyticsEnabled = false

	state = { 
		isOpen: true
	}

	onCloseClick() {
		this.performClose();
		this.props.onModalClosedWithoutChanges();
	};

	performClose(){
		this.setState({isOpen : false});
	}

	saveCookies() {
		if(this.analyticsEnabled){
			CookieState.enableAnalyticsCookies();
			window["grantAnalyticsConsent"]();
		}else{
			CookieState.disableAnalyticsCookies();
			window["revokeAnalyticsConsent"]();
		}
		this.performClose();
	}

	// remove default styles
	 modalStyle = {
		overlay: {
			backgroundColor: null,
		},
		content: {
			top: null,
			left: null,
			right: null,
			bottom: null,
			border: null,
			background: null,
			borderRadius: null,
			padding: null,
			position: null,
		},
	};

	 onAnalyticsCheckChanged(checked){
		this.analyticsEnabled = checked
	}

	render() {
		const { isOpen } = this.state;
		return (
			<ReactModal
				closeTimeoutMS={500}
				isOpen={isOpen}
				onRequestClose={this.performClose}
				style={this.modalStyle}>
				<button className='close-modal' onClick={this.onCloseClick.bind(this)}>
					<span className='material-icons-round'>close</span>
				</button>
				<Overflow className='scrollable' tolerance={10}>
					<Overflow.Content className='scrollable-content'>
						<h2>Cookie Preferences</h2>
						<Cookies onAnalyticsCheckChanged={this.onAnalyticsCheckChanged.bind(this)} />
					</Overflow.Content>
					<Overflow.Indicator direction='down'>
						{(canScroll) => (
							<OverflowGradient direction='down' isVisible={canScroll} />
						)}
					</Overflow.Indicator>
					<Overflow.Indicator direction='up'>
						{(canScroll) => (
							<OverflowGradient direction='up' isVisible={canScroll} />
						)}
					</Overflow.Indicator>
				</Overflow>

				<Button
					onClick={this.saveCookies.bind(this)}
					buttonSize='btn--large'
					buttonStyle='btn--primary'
					className='confirm'
				>
					<span className='material-icons-round'>done</span>
					Save preferences
				</Button>
			</ReactModal>
		);
	};
}

export default CookieConsentModal;
