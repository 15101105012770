import React from 'react';
import { Link } from 'react-scroll';
import './IndexItem.sass';

/**
 * Properties: 
 * 
 * title - title of the index entry
 * 
 * destination - destination id of the text section
 * 
 * offset - distance from the top
 * 
 * smoothScroll - enable smooth scroll to the destination 
 * 
 * @param {*} props 
 */
function IndexItem(props) {
	return (
		<>
			<Link
				className='index-menu__link'
				to={props.destination}
				smooth={props.smoothScroll}
				offset={props.offset}
			>
				<div className=''>{props.title}</div>
			</Link>
		</>
	);
}

IndexItem.defaultProps = {
	offset: -150,
	smoothScroll: true
}

export default IndexItem;