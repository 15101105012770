import React from 'react';
//import '../css/App.sass';
import './css/EarlyAccess.sass';
import SmallFooter from '../components/footer/SmallFooter';
import { GooglePlayButton } from '../components/button/GooglePlayButton';

function EarlyAccess() {
	return (
		<>
			<div className='early-access'>
				<div className='top-section'>
					<div className='logo'>
						<img alt='Kontas App Logo' src='./images/kontas-logo_192.png'></img>
						<div className='right-content'>
							<h2 className='name'>Kontas</h2>
							<p className='description'>Take control of your finances</p>
						</div>
					</div>
					<div className='early-access-btn'>
						<p>Available in Early Access</p>
						<GooglePlayButton />
					</div>
				</div>

				<div className='floating-elements'>
					<div className='local_mall level3'>
						<div className='floating'>
							<span className='material-icons-round local_mall'>
								local_mall
							</span>
						</div>
					</div>
					<div className='spa level2'>
						<div className='floating'>
							<span className='material-icons-round spa'>spa</span>
						</div>
					</div>
					<div className='theaters level1'>
						<div className='floating'>
							<span className='material-icons-round theaters'>theaters</span>
						</div>
					</div>
					<div className='restaurant level3'>
						<div className='floating'>
							<span className='material-icons-round restaurant'>
								restaurant
							</span>
						</div>
					</div>
					<div className='shopping_cart level5'>
						<div className='floating'>
							<span className='material-icons-round shopping_cart'>
								shopping_cart
							</span>
						</div>
					</div>
					<div className='home level4'>
						<div className='floating'>
							<span className='material-icons-round home'>home</span>
						</div>
					</div>
				</div>
				<SmallFooter />
			</div>
		</>
	);
}

export default EarlyAccess;
