import React from 'react';
import './CookieConsentBar.sass';
import CookieConsentModal from './CookieConsentModal';
import '../modal/Modal.sass';
import cookieState from './CookieState';

class CookieConsentBar extends React.Component {

	state = { 
		showingBar: true,
		showingModal: false,
	}

	show(){
		this.setState({showingBar: true});
	}

	hide(){
		this.setState({showingBar: false});
	}

	acceptCookies(){
		this.hide();
		cookieState.enableAllCookies();
	}

	manageCookies(){
		this.setState({showingBar: false, showingModal: true});
	};

	onModalClosedWithoutChanges(){
		this.setState({showingBar: true, showingModal: false});
	}

	render() {
		const { showingBar, showingModal } = this.state;
		if(!cookieState.areCookiesEmpty()){
			return null;
		}
		return (
			<>	{ showingBar ? 
					<div className={`CookieConsent`}>
						<p className='content'>
							We use cookies to analyse our traffic to improve the website.{' '}
							<span className='more-info' onClick={this.manageCookies.bind(this)}>
								Learn more
							</span>
						</p>
						<div className='buttons'>
							<button className='AcceptCookies' onClick={this.acceptCookies.bind(this)}>Accept</button>
							<button onClick={this.manageCookies.bind(this)} className='ManageCookies'>
								Manage Cookies
							</button>
						</div>
					</div> 
					: null 
				}
				
				{ showingModal ? 
					<CookieConsentModal 
						onModalClosedWithoutChanges={this.onModalClosedWithoutChanges.bind(this)}/> 
						: null}
			</>
		);
	};
}

export default CookieConsentBar;
