import React from 'react';
import { Button } from '../components/button/Button';
import './css/ManageCookies.sass';
import ToggleSwitch from '../components/toggle/ToggleSwitch';
import SmallFooter from '../components/footer/SmallFooter';

//Partials
import Navbar from '../partials/Navbar';
import CookieState from '../components/cookies/CookieState';

class ManageCookies extends React.Component {

	state = {
		analyticsEnabled : CookieState.areAnalyticsCookiesEnabled()
	};

	onAnalyticsStateChanged(enabled){
		this.setState({
			analyticsEnabled : enabled
		});
	}

	applyChanges(){
		if(this.state.analyticsEnabled){
			CookieState.enableAnalyticsCookies();
			window["grantAnalyticsConsent"]();
		}else{
			CookieState.disableAnalyticsCookies();
			window["revokeAnalyticsConsent"]();
		}
	}

	render() {
		return (
			<>
				<Navbar />
				<div className='manage-cookies'>
					<div className='content-container'>
						<h1>Cookie Preferences</h1>

						<div className='main-section'>
							<br/>
							<p>
								Cookies are small files used to store information on web browsers.
							</p>
						</div>
						<div className='section'>
							<div className='title'>
								<h4>Analytics Cookies</h4>
								<ToggleSwitch defaultChecked={this.state.analyticsEnabled} onCheckedChanged={this.onAnalyticsStateChanged.bind(this)} />
							</div>
							<p>
								We use these cookies to store identifiers used by our analytics partners.
								The information collected is anonymised.
							</p>
						</div>

						<div className='cookie-footer-label'>
							<p>
								You can change these settings anytime by visiting Cookies at the bottom of any page
							</p>
						</div>
						<Button
							onClick={this.applyChanges.bind(this)}
							buttonSize='btn--large'
							buttonStyle='button-secondary'
							className='confirm'>
							<span className='material-icons-round'>done</span>
							Save preferences
						</Button>
					</div>

					<SmallFooter />
				</div>
			</>
		);
	}
}

export default ManageCookies;
