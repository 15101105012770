import React from 'react';
import SmallFooter from '../components/footer/SmallFooter';
import './css/PrivacyPolicy.sass';
import Navbar from '../partials/Navbar';
import IndexItem from '../components/text/IndexItem';

function PrivacyPolicy() {
	//const { t, i18n } = useTranslation();

	return (
		<>
			<div className='privacy-policy'>
				<Navbar />
				<div className='privacy-policy-container'>
					<div className='index-menu'>

						<IndexItem
							title={'Information we collect'} 
							destination={'information-we-collect'}>
						</IndexItem>

						<IndexItem
							title={'How we use your information'} 
							destination={'how-we-use-your-information'}>
						</IndexItem>

						<IndexItem
							title={'How this information is shared'} 
							destination={'how-is-this-information-shared'}>
						</IndexItem>

						<IndexItem
							title={'Third-party partners privacy policies'} 
							destination={'third-party-privacy-policies'}>
						</IndexItem>

						<IndexItem
							title={'California Consumer Privacy Act (CCPA)'} 
							destination={'ccpa-privacy-rights'}>
						</IndexItem>

						<IndexItem
							title={'GDPR Data Protection Rights'} 
							destination={'gdpr-data-protection-rights'}>
						</IndexItem>

						<IndexItem
							title={'Children\'s Information'} 
							destination={'childrens-information'}>
						</IndexItem>

					</div>

					<div className='content-section'>
						<div className='privacy-policy'>
							<h1>Privacy Policy</h1>

							<p> Last updated on: 01/02/2020</p>

							<p>
								This Privacy Policy describes the information that is collected 
								by Kontas and how we use it.
							</p>

							<p>
								This policy applies to our website and application. It is not applicable 
								to any information collected offline or
								other channels other than our website and application.
							</p>

							<p>
								By using our website and application, you hereby consent to our Privacy Policy
								and agree to its terms.
							</p>

							<p>
								If you have additional questions or require more information
								regarding this policy, do not hesitate to contact us on: contact@kontas.app
							</p>
						</div>
						<div className='information-we-collect'>
							<h2>Information we collect</h2>
							
							<p>We do not collect any personally identifiable information in our services.
								However, if you contact us directly, we may receive additional
								information about you such as your name, email address,
								the contents of the message and/or attachments you may
								send us, and any other information you may choose to provide.
							</p>

							<p>
								We collect the following anonymous information with your consent:
							</p>

							<ul>
								<li>Analytics events from your interactions</li>
								<li>Crash reports that include your Android version and other device properties</li>
							</ul>
						</div>
						<div className='how-we-use-your-information'>
							<h2>How we use your information</h2>

							<p>
								The analytics events we collect are used in various ways, including to:
							</p>

							<ul>
								<li>Provide, operate, and maintain our website and application</li>
								<li>Understand and analyze how you use our website and application</li>
								<li>
									Develop new features, improving existing functionality and fixing errors
								</li>
							</ul>
						</div>
						<div className='how-is-this-information-shared'>
							<h2>How this information is shared</h2>

							<p>
								We work with third-party partners who helps us provide our website and application.
								Your non personally identifiable information is shared with our partners.
							</p>
							<p>We share information with the following types of third parties:</p>
							<ul>
								<li>Analytics services providers</li>
								<li>Advertisers</li>
							</ul>
						</div>
						<div className='third-party-privacy-policies'>
							<h2>Third Party Privacy Policies</h2>

							<p>
								Kontas's Privacy Policy does not apply to other advertisers or
								websites. Thus, we advise you to consult the respective
								Privacy Policies of these third-party partners for more
								detailed information. It may include their practices and
								instructions about how to opt-out of certain options.
							</p>

							<ul>
								<li>
									<p>
										<a href='https://firebase.google.com/support/privacy'
										target="_blank" rel="noopener noreferrer">
										Firebase
										</a>
									</p>
								</li>
								<li>
									<p>
										<a href='https://developers.google.com/analytics/policy'
										target="_blank" rel="noopener noreferrer">
										Google Analytics
										</a>
									</p>
								</li>
								<li>
									<p>
										<a href='https://support.google.com/admob/answer/6128543'
										target="_blank" rel="noopener noreferrer">
										Google Admob
										</a>
									</p>
								</li>
							</ul>

						</div>
						<div className='ccpa-privacy-rights'>
							<h2>California Consumer Privacy Act (CCPA)</h2>

							<p>
								Under the CCPA, among other rights, California consumers have
								the right to:
							</p>
							<ul>
								<li>
									Request any data removal from our services.
								</li>
								<li>
									Request any data collected and controlled by our services.
								</li>
							</ul>
						</div>
						<div className='gdpr-data-protection-rights'>
							<h2>GDPR Data Protection Rights</h2>
							<p>
								Under the General Data Protection Regulation, you have the right to access, modify,
								transfer and delete your data. 
							</p>
							<p>
								The information you insert in the app stays in your device and is not sent to us or anyone else.
							</p>
							<p>
								To delete your data, all you need to do is uninstall the application.
							</p>
						</div>
						<div className='childrens-information'>
							<h2>Children's Information</h2>

							<p>
								Kontas is not a service directed for children. 
								We do not collect any personally identifiable
								information from children under the age of 13.
							</p>
						</div>
					</div>
				</div>
				<SmallFooter />
			</div>
		</>
	);
}

export default PrivacyPolicy;
